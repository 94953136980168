// Navbar
.btn-primary:hover {
    background-color: $color-adp-bleu-clair;
}

.btn-primary:active,
.btn-primary.active,
.open>.btn-primary.dropdown-toggle {
    background-color: $color-adp-bleu-clair;
    &:hover,
    &:focus {
        background-color: $color-adp-bleu-clair;
    }
}

.navbar-brand {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    img {
        max-height: 40px;
    }
}

.menu-trigger {
    float: left;
    padding: 4px 10px;
    margin-top: 8px;
    margin-left: 15px;

    color: $color-adp-bleu;

    &:hover {
        color: $color-adp-rouge;
    }
}

.navbar-form.search-form {
    width: calc(100% - 52px);
    position: relative;
    float: left;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-top: 0;
    padding-bottom: 0;
    @media (min-width: $screen-sm-min) {
        width: 60%;
        margin-left: calc(20% - 52px);
    }
    .form-group {
        display: block;
    }
    .form-control {
        width: 100%;
    }
}

.navbar{
    height: 50px;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
}
