.user-not-logged {
    padding: 0px;

    .home-header {
        min-height: 130vh;
        padding-top: 7vh;
    }

    .content .panel {
        background: white;
        text-align: left;
    }
}

.home-header {
    .row {
        justify-content: center;
    }

    .form-group{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
