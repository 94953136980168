//== Main variables
//
//##

$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

$screen-md:                  992px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

$font-family-sans-serif: "Century Gothic", Arial, Helvetica, sans-serif;
$icon-font-path: "../fonts/";
$font-size-base: 16px;
$line-height-base: 1.5;

$line-height-computed: floor(($font-size-base * $line-height-base));

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: #43444A; // lighten($gray-base, 20%) !default;   // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

$color-adp-bleu: #031F73;
$color-adp-rouge: #C84118;
$color-adp-bleu-clair: #376DB3;
$color-adp-blanc: #ffffff;
$color-adp-gris: #F5F5F5;

$brand-primary: $color-adp-bleu;
$brand-success: #5cb85c;
$brand-info: $color-adp-rouge;
$brand-warning: #F9E06B;
$brand-danger: #d9534f;

$screen-sm-min: 768px;
$screen-lg-min: 1280px;

// $icon-font-path:          "../fonts/";
// $icon-font-name:          "glyphicons-halflings-regular";

$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: #FFF;
//** Global text color on `<body>`.
$text-color: $gray-dark;

//** Global textual link color.
$link-color: $color-adp-bleu;
//** Link hover color set via `darken()` function.
$link-hover-color: $color-adp-rouge;
//** Link hover decoration.
$link-hover-decoration: underline;

//** Breadcrumb bg color
$breadcrumb-bg: transparent;
// $breadcrumb-separator: "→";
// $breadcrumb-color: $color-adp-bleu;


//== Navbar
//
//##

// Basics of a navbar
$navbar-default-color: $color-adp-bleu-clair;
$navbar-default-bg: $color-adp-blanc;
$navbar-default-border: transparent;

// Navbar links
$navbar-default-link-color: $color-adp-bleu-clair;
$navbar-default-link-hover-color: $color-adp-bleu;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: $color-adp-bleu;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color !default;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%) !default;
$navbar-default-brand-hover-bg: transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd !default;
$navbar-default-toggle-icon-bar-bg: #888 !default;
$navbar-default-toggle-border-color: #ddd !default;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: normal !default;

$btn-default-color: $color-adp-bleu;
$btn-default-bg: $color-adp-blanc;
$btn-default-border: $color-adp-bleu;

$btn-primary-color: $color-adp-blanc;
$btn-primary-bg: $color-adp-bleu;
$btn-primary-border: transparent;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: transparent;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: transparent;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: transparent;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: transparent;

$btn-link-disabled-color: $gray-light !default;
$enable-shadows: true;
