// menu sidebar (hamburger menu)
.mp-menu {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 1040;
    background: $color-adp-blanc;
    width: 100%;
    height: 100%;
    margin-left: -100%;
    transition: margin-left 300ms ease;
    &.open {
        margin-left: 0;
    }
    @media (min-width: $screen-sm-min) {
        width: 270px;
        margin-left: -270px;
        border-right: 1px solid $gray-lighter;
    }
    .mp-level {
        background: $color-adp-blanc;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        min-height: 100%;
        margin-left: -100%;
        overflow-y: hidden;
        transition: margin-left 300ms ease;
        &.open {
            margin-left: 0;
        }
        .mp-level {
            z-index: 1041;
            .mp-level {
                z-index: 1042;
            }
        }
        ul {
            overflow-y: scroll;
            height: 100%;
        }
        a {
            display: block;
            padding: ($line-height-computed / 2) $line-height-computed;
            &:hover {
                text-decoration: none;
                color: $color-adp-rouge;
            }
        }
        li {
            border-top: 1px solid $gray-lighter;
            // &:first-child {border: 0px;}
            &:last-child {
                border-bottom: 1px solid $gray-lighter;
            }
        }
    }
    .mp-back {
        border-top: 1px solid $gray-lighter;
        padding: ($line-height-computed / 2) $line-height-computed;
        float: none;
        width: 100%;
        text-align: right;
    }
    .mp-bottom {
      padding: 25px;
    }
    i {
        padding: 0px 5px 0px 0px;
    }
}
