// serach-form
.showcase {
    height: 80vh;
    img {
        padding: $line-height-computed;
        margin: 0 auto;
    }
    h3 {
        font-weight: 400;
    }
}

.vcenter {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
}

.algolia-autocomplete .aa-input,
.algolia-autocomplete .aa-hint {
    width: 100%;
}

.algolia-autocomplete .aa-hint {
    color: #999;
}

.algolia-autocomplete .aa-dropdown-menu {
    width: 100%;
    background-color: #fff;
    border: 1px solid #999;
    border-top: none;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
    cursor: pointer;
    padding: 5px 4px;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor {
    background-color: #B2D7FF;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion em {
    font-weight: bold;
    font-style: normal;
}

.algolia-autocomplete {
    .extract {
        font-size: 1.3rem;
    }
}

.search-result-title {
    display: block;
    color: $color-adp-bleu;
    font-weight: bold;
    font-size: 1.7rem;
}

.algolia-autocomplete {
    width: 100%;
    text-align: left;
}

.search-btn {
    border: none;
    outline: none;
    position: absolute;
    height: 37px;
    width: 54px;
    top: 0;
    right: 15px;
    border-left: 1px solid rgba(255, 255, 255, 0.32);
    cursor: pointer;
    z-index: 1;
    padding: 0;
    background: url("../img/loupe-blue.svg") no-repeat center center transparent;
    background-size: 22px 23px;
}