// table-des-matieres
.sidebar.table-des-matieres {
    background-color: $color-adp-bleu;
    color: $color-adp-blanc;
    margin-bottom: -150px;
    margin-top: 30px;
    z-index: 10;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 1px;
    margin-bottom: -150px;
    margin-top: 30px;
    // transition: margin 600ms ease;
    &.affix-top {
        position: absolute;
        margin-top: -80px;
        // margin-right: 9.833px;
    }
    &.affix {
        margin-bottom: 0;
        margin-top: -150px;
    }
    h4 {
        color: $color-adp-blanc;
        font-weight: normal;
        text-transform: uppercase;
    }
    a {
        width: 100%;
        text-align: left;
        color: $color-adp-blanc;
        display: block;
        padding: 10px 15px;
        letter-spacing: 1px;
    }
}

// table des matière / dropdown-menu
.dropdowned.table-des-matieres .dropdown-menu {
    color: $color-adp-blanc;
    background-color: $color-adp-bleu;
    border: 0;
    @include box-shadow(0);
    >li>a {
        color: $color-adp-blanc;
        &:hover {
            background-color: $color-adp-bleu-clair;
        }
    }
}

// table des matière / menu bleu
.collapsable {
  &.affix {
      top: 50px;
      left: 0;
      right: 0;
      margin-top: 0;
      z-index: 10;
  }
  .table-des-matieres {
    margin: 0 auto;
    padding: 0;
    button,
    a {
        width: 100%;
        text-align: left;
        color: $color-adp-blanc;
        background: $color-adp-bleu;
        display: block;
        padding: 10px 15px;
        letter-spacing: 1px;
    }
    button {
        position: relative;
        text-transform: uppercase;
        &.collapsed .glyphicon-chevron-up::before {
            content: "\E114";
        }
    }
    span.glyphicon {
        right: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
}
