// footer buttons
.footer-btn {
    color: $color-adp-bleu;
    font-size: 170%;
    @media (min-width: $screen-sm-min) {
        font-size: 200%;
    }

    &.active,
    &:focus {
        color: $color-adp-rouge;
    }
}

.nav.list-inline > li {
    display: inline-block;
}

.navbar-nav.list-inline {
    margin: 0px;
}

.navbar-fixed-top {
    border-bottom: 1px solid $gray-lighter;
    background: white;
    padding: 0;

    > .container-flexible {
        width: 100%;
    }
}

.navbar-fixed-bottom {
    margin-bottom: -70px;
    transition: margin-bottom 300ms ease;
    border-top: 1px solid $gray-lighter;

    &.open,
    &:hover {
        margin-bottom: 0px;
    }
}
