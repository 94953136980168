// Page header
.page-header {
    .breadcrumb {
        margin-bottom: $line-height-computed / 2;
        padding-bottom: 0;
        padding-left: 0;
        font-size: 88%;

        a {
            color: white;
            font-weight: bold;
        }
    }

    h1 {
        margin-top: $line-height-computed / 2;
    }

    @media (min-width: $screen-sm-min) {
        margin-bottom: 80px;
    }
    @media (max-width: $screen-xs-max) {
        padding-bottom: 0px;
    }
    border-bottom: 0px;
}

// copy button
section {
    margin-bottom: $line-height-computed * 2;
}

.copypaste {
    position: relative;

    .invisible {
        visibility: visible;
        opacity: 0;
        position: relative;
        bottom: 0;
        right: 0;
        transition: opacity 300ms ease;
    }

    &:hover .invisible {
        visibility: visible;
        opacity: 1;
    }

    .bg-blanc {
        padding-bottom: $line-height-computed;
    }
}

@media (max-width: $screen-xs-max) {
    .copypaste {
        .invisible {
            opacity: 1;
        }
    }
}

// memo ol list style
.memo {
    ol {
        counter-reset: item;
        list-style: none;
        @extend .list-unstyled !optional;
        @extend .row !optional;

        li {
            @extend .col-sm-12 !optional;
            @extend .col-lg-6 !optional;
            counter-increment: item;
            position: relative;
            padding-bottom: $line-height-computed;
            padding-left: 16.66666667%;
            @media (min-width: $screen-sm-min) {
                padding-left: 8.33333333%;
            }

            &:before {
                content: counter(item);
                color: $color-adp-rouge;
                font-weight: bold;
                @extend .h1 !optional;
                position: absolute;
                top: 0;
                left: 0;
                text-align: center;
                width: 16.66666667%;
                @media (min-width: $screen-sm-min) {
                    width: 8.33333333%;
                }
                @media (min-width: $screen-lg-min) {
                    width: 16.66666667%;
                }
                margin-top: 0;
            }

            p {
                padding: 0 0 ($line-height-computed / 2);
                @extend .col-xs-10 !optional;
                @extend .col-sm-11 !optional;
            }
        }
    }
}

// memo paragraph style
.memo {
    h3 {
        @extend .h2 !optional;
        font-size: 24px;
        line-height: 1;
        color: $color-adp-rouge;
    }
}

// memo links style
.memo .liens {
    // @extend .col-sm-10;
    // @extend .col-sm-offset-1;
    @extend .list-group !optional;

    a {
        @extend .list-group-item !optional;
        border-right: 0;
        border-left: 0;

        &:first-child {
            border-top: 0;
        }
    }
}
