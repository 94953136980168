// import custom variables
@import 'fonts';
@import './variables';
// import bootstrap framework's sass files (only the necessary)
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../../../node_modules/bootstrap/scss/mixins/box-shadow";

// import customs styles
@import 'menu';
@import 'navbar';
@import 'memo';
@import 'table-matieres';
@import 'footer';
@import 'search';
@import 'guest';
@import 'node_modules/font-awesome/scss/font-awesome';
// typographic styles
img {
    @extend .img-responsive !optional;
}

body {
    padding-top: 50px;
    font-family: "Century Gothic", Arial, Helvetica, sans-serif;
}

main.container {
    min-height: 85vh;
}

.bg-blanc {
    background: $color-adp-blanc;
}

.bg-bleu {
    background: $color-adp-bleu;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $color-adp-bleu;
    font-weight: bold;
    padding: ($line-height-computed / 2) 0;
}

small {
    color: $color-adp-bleu-clair;
}

.rouge {
    color: $color-adp-rouge;
}

button[type='submit'] {
    width: 100%;
    border-radius: 0;
}

.panel-heading {
    text-align: center;
    margin-bottom: 30px;
}

.page-header {
    margin-top: 30px;
    margin-bottom: 30px;
}

.header {
    text-align: center;
    padding-bottom: 80px;

    img {
        height: 120px;
        display: block;
        margin: auto;
        margin-top: 10px;
    }

    div.title {
        font-size: 3rem;
        color: $color-adp-bleu;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.home-header {
    padding-top: 5vh;
    background: url('/img/header.jpg');
    background-position: center;
    background-size: cover;

    .search-form {
        margin-bottom: 30px;
    }
}


/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {
    .header {
        // height: 250px;
        img {
            height: 50px;
        }

        div.title {
            font-size: 2rem;
        }
    }
}

.page-header-image {
    background-position: center;
    background-size: cover;
    background-color: $color-adp-bleu-clair;
    background: url('/img/header.jpg');
    background-position: center;
    background-size: cover;

    h1 {
        color: white;
    }
}

.content-table {
    .created_at {
        color: $color-adp-bleu-clair;;
        display: block;
        font-weight: normal;
        font-size: 12px;
    }

    thead {
        background: $color-adp-bleu;
        color: white;
    }

    a {
        font-size: 18px;
        font-weight: bold;
        color: $color-adp-bleu;

        &:hover {
            color: $color-adp-rouge;
            text-decoration: underline;
        }
    }

    .btn-info {
        color: #fff;
        background-color: #C84118;
        border-color: transparent;
        border-radius: 0;
    }

    .btn-xs {
        padding: 1px 5px;
    }
}

.title-orange {
    color: $color-adp-rouge;
}

.not-published-notification {
    position: fixed;
    top: 50px;
    width: 100%;
    z-index: 100;
}

input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #ccc;
    box-shadow: none;
    border-radius: 0px;
}

section {
    table {
        @extend .table !optional;
    }

    img {
        @extend .img-responsive !optional;
    }
}

.mp-menu .mp-level ul {
    overflow-y: auto;
    height: 100%;
}

#app {
    overflow: hidden;
}


.mp-menu {
    li, li a {
        color: $color-adp-bleu !important;
    }
}

.container {
    .breadcrumb {
        padding: 8px 15px;
        margin-bottom: 24px;
        list-style: none;
        background-color: transparent;
        border-radius: 0;

        a {
            color: $color-adp-bleu !important;
            text-decoration: none;
        }

        li + li:before {
            content: "/ ";
            padding: 0 5px;
            color: #ccc;
        }
    }
}

.navbar-fixed-bottom {
    bottom: 0;
    top: unset;
    padding: 0;

    .list-inline {
        flex-direction: row;

        li {
            padding: 0 20px;
        }
    }
}

.copy-btn {
    background: $color-adp-bleu;
    border-radius: 0;
}

.navbar-nav {
    .text-center {
        a {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
}


.holder-table-des-matieres {
    display: block;
    margin-left: 75%;
}

.table-des-matieres.affix {
    position: fixed;
}

.btn-primary {
    background-color: $color-adp-bleu;
    border-color: $color-adp-bleu;
}

.panel-default {
    > .panel-heading {
        color: #43444A;
        background-color: #f5f5f5;
        border-color: #ddd;

        padding: 20px;
    }

    .panel-body {
        padding: 15px;
    }
}


.modal-footer {
    .btn {
        border-radius: 0;
    }

    .btn-default {
        border: solid 1px #010f37;

        &:hover {
            background: #e6e5e5;
        }
    }
}

.modal-header {
    flex-direction: row-reverse;

}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
        margin: 1.75rem auto;
    }
}


.profile-edit-footer {
    input {
        border-radius: 0;
    }
}

.login-group {
    a {
        color: #031F73;
    }
}

.reset-body{
    label{
        text-align: center;
    }
}


.login-text-color{
    color: #0056b3;
}
